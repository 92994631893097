export const getEnvironment: () => Environment = () => 
    (process.env.REACT_APP_ENVIRONMENT ?? 'CLIENTE') as Environment;


export type Environment = "PARCEIRO" | "CLIENTE";

export const isAuthenticated = () => {
    if (getEnvironment() === "PARCEIRO")
        return !!sessionStorage.getItem("contactEmail");

    return !!sessionStorage.getItem("token");
}

export const isParceiroEnvironment = () => getEnvironment() === "PARCEIRO"