import { ItemProps } from "../../CardInformation";
import "./DataContact.css";

interface CardPropsHolder {
  name: string;
  email: string;
  phone: string;
}
// interface CardPropsHolder {
//   data: { label: string; value: string }[];
// }
const formatedName = (item: ItemProps) => {
  const labels = ["E-mail"];
  if (labels.includes(item.label)) {
    if (item.value.length > 20) {
      return true;
    }
  }
  return false;
};

const DataContact = ({ name, email, phone }: CardPropsHolder) => {
  // console.log(data);
  return (
    <section className="section-datas-contact" id="template-areas">
      <div id="name-contact-div">
        <h4 className="label-data-contact">Nome</h4>
        <p className="value-contact">{name}</p>
      </div>
      <div id="email-contact-div">
        <h4 className="label-data-contact">E-mail</h4>
        <p className="value-contact-email">{email}</p>
      </div>
      <div id="phone-contact-div">
        <h4 className="label-data-contact">Celular (Whatsapp) </h4>
        <p className="value-contact">{phone}</p>
      </div>
      {/* <div className="container-label-value-contact-name">
        <h4 className="label-data-contact">Nome</h4>
        <p className="value-contact">{name}</p>
      </div>
      <div className="container-label-value-contact-email">
        <h4 className="label-data-contact">E-mail</h4>
        <p className="value-contact-email">{email}</p>
      </div>
      <div className="container-label-value-contact">
        <h4 className="label-data-contact">Celular (Whatsapp) </h4>
        <p className="value-contact">{phone}</p>
      </div> */}
    </section>
  );
};

export default DataContact;
