import {
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Home } from "./pages/Home";
import { DadosParceiro } from "./pages/dadosParceiro";
import { Contact } from "./pages/contact";
import { Validation } from "./pages/validation";
import { Holder } from "./pages/holder";
import { CreateHolder } from "./pages/holder/create";
import { CreateHolderCNPJ } from "./pages/holder/create-cnpj";
import { Installation } from "./pages/installation";
import { ConfirmYourData } from "./pages/confirmYourData";
import { ContractOfAdhesion } from "./pages/contractOfAdhesion";
import { SelectYourDistributor } from "./pages/SelectYourDistributor";
import WeAreAlmostThere from "./pages/SelectYourDistributor/WeAreAlmostThere";
import RegistrationCompleted from "./pages/registrationCompleted";
import "tailwindcss/tailwind.css";
import { NextUIProvider } from "@nextui-org/system";
import { PageNotFound } from "./pages/PageNotFound/PageNotFound";
import PdfErrorValidation from "./pages/pdfErrorValidation";
import { UnapprovedAccount } from "./pages/UnapprovedAccount";
import { RedirectStorage } from "./context/redirect";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  AuthenticatedStorage,
} from "./context/authenticated";
import { SessionStorageStorage } from "./context/session";
import { getEnvironment, isAuthenticated, isParceiroEnvironment } from "./services/environment";

const queryClient = new QueryClient();

export const PrivateRoute = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/" />;
};

function App() {
  const getEnvironmentRoutes: () => JSX.Element[] = () => {
    if (isParceiroEnvironment())
      return [
        <Route key="dados-parceiro" path="/" element={<DadosParceiro />} />,
        <Route key="distribuidora" path="/distribuidora" element={<Home />} />
      ];
      
    return [
      <Route key="distribuidora" path="/" element={<Home />} />
    ];
  }

  return (
    <NextUIProvider>
      <QueryClientProvider client={queryClient}>
        <RedirectStorage>
          <AuthenticatedStorage>
            <SessionStorageStorage>
              <div className="App">
                <Routes>
                  {
                    getEnvironmentRoutes()
                  }
                  <Route path="/" element={<Home />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/validation" element={<Validation />} />

                  {/* Rota protegida: */}
                  <Route element={<PrivateRoute />}>
                    <Route path="/holder" element={<Holder />} />
                    <Route path="/create-holder" element={<CreateHolder />} />
                    <Route
                      path="/create-holder-cnpj"
                      element={<CreateHolderCNPJ />}
                    />
                    <Route
                      path="/installation"
                      element={<Installation />}
                      errorElement={<PageNotFound />}
                    />
                    <Route
                      path="/confirm-your-data"
                      element={<ConfirmYourData />}
                      errorElement={<PageNotFound />}
                    />
                    <Route
                      path="/contract-of-adhesion"
                      element={<ContractOfAdhesion />}
                      errorElement={<PageNotFound />}
                    />
                    <Route
                      path="/registration-completed"
                      element={<RegistrationCompleted />}
                    />
                  </Route>

                  <Route
                    path="/select-your-distributor"
                    element={<SelectYourDistributor />}
                  />
                  <Route
                    path="/pdf-error-validation"
                    element={<PdfErrorValidation />}
                  />
                  <Route
                    path="/we-are-almost-there"
                    element={<WeAreAlmostThere />}
                  />
                  <Route path="/page-not-found" element={<PageNotFound />} />
                  <Route
                    path="/unapproved-account"
                    element={<UnapprovedAccount />}
                  />

                  {/* Rota curinga para redirecionar para a raiz se o caminho não corresponder a nenhum dos acima */}
                  <Route path="*" element={<Navigate to="/page-not-found" />} />
                </Routes>
              </div>
            </SessionStorageStorage>
          </AuthenticatedStorage>
        </RedirectStorage>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </NextUIProvider>
  );
}

export default App;
