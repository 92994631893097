import confirmYourData from "../assets/confirmYourData/confirmYourData.svg";
import contact from "../assets/contact/contact.svg";
import contractOfAdhesion from "../assets/contractOfAdhesion/contractOfAdhesion.svg";
import contaDeLuz from "../assets/holder/conta-de-luz.svg";
import imgIvi from "../assets/home/home.svg";
import installation from "../assets/installation/installation.svg";
import selectYourDistributor from "../assets/selectYourDistributor/selectYourDistributor.svg";
import validation from "../assets/validation/validation.svg";

import imgIviVivo from "../assets/fotos-vivo/sun-energy-icon.svg";
import contactVivo from "../assets/fotos-vivo/sun-icon.svg";
import validationVivo from "../assets/fotos-vivo/sun-icon.svg";

import contaDeLuzVivo from "../assets/fotos-vivo/building-icon.svg";
import installationVivo from "../assets/fotos-vivo/lamp-icon.svg";
import confirmYourDataVivo from "../assets/fotos-vivo/lamp-icon.svg";
import contractOfAdhesionVivo from "../assets/fotos-vivo/lamp-icon.svg";
const selectYourDistributorVivo = imgIviVivo;

type keys =
  | "confirmYourData"
  | "contact"
  | "contractOfAdhesion"
  | "contaDeLuz"
  | "imgIvi"
  | "installation"
  | "selectYourDistributor"
  | "validation";

const images = {
  confirmYourData,
  contact,
  contractOfAdhesion,
  contaDeLuz,
  imgIvi,
  installation,
  selectYourDistributor,
  validation,
};

const imagesVivo = {
  confirmYourData: confirmYourDataVivo,
  contact: contactVivo,
  contractOfAdhesion: contractOfAdhesionVivo,
  contaDeLuz: contaDeLuzVivo,
  imgIvi: imgIviVivo,
  installation: installationVivo,
  selectYourDistributor: selectYourDistributorVivo,
  validation: validationVivo,
};

export const getImage = (imgId: keys) => imagesVivo[imgId] ?? "";
