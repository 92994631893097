import { ItemProps } from "../../CardInformation";
import "./DataHolder.css";

interface CardPropsHolder {
  name: string;
  nationalite: string;
  cpf: string;
  rgRneLabel: string;
  rgRneValue: string;
  maritalStatus: string;
}
// interface CardPropsHolder {
//   data: { label: string; value: string }[];
// }

const formatedName = (item: ItemProps[]) => {
  if (item.length === 2) return true;
  return false;
};

const formatedNameValue = (item: ItemProps) => {
  const labels = ["Nome"];
  if (labels.includes(item.label)) {
    if (item.value.length > 20) {
      return true;
    }
  }

  return false;
};

const DataHolder: React.FC<CardPropsHolder> = ({
  name,
  nationalite,
  cpf,
  rgRneValue,
  rgRneLabel,
  maritalStatus,
}) => {
  return (
    <section className="section-datas" id="template-areas">
      <div id="name-div">
        <h4 className="label-data-holder">Nome</h4>
        <p className="value-data-holder-name">{name}</p>
      </div>
      <div id="nacionalite-div">
        <h4 className="label-data-holder">Nacionalidade</h4>
        <p className="value-data-holder">{nationalite}</p>
      </div>
      <div id="cpf-div">
        <h4 className="label-data-holder">CPF</h4>
        <p className="value-data-holder">{cpf}</p>
      </div>
      <div id="rg-div">
        <h4 className="label-data-holder">{rgRneLabel}</h4>
        <p className="value-data-holder">{rgRneValue}</p>
      </div>
      <div id="marital-status-div">
        <h4 className="label-data-holder">Estado Civil</h4>
        <p className="value-data-holder">{maritalStatus}</p>
      </div>
    </section>

    // <section className="section-datas">
    //   <div className="container-label-value-holder-name">
    //     <h4 className="label-data-holder">Nome</h4>
    //     <p className="value-data-holder-name">{name}</p>
    //   </div>
    //   <div className="container-label-value-holder">
    //     <h4 className="label-data-holder">Nacionalidade</h4>
    //     <p className="value-data-holder">{nationalite}</p>
    //   </div>
    //   <div className="container-label-value-holder">
    //     <h4 className="label-data-holder">CPF</h4>
    //     <p className="value-data-holder">{cpf}</p>
    //   </div>
    //   <div className="container-label-value-holder">
    //     <h4 className="label-data-holder">{rgRneLabel}</h4>
    //     <p className="value-data-holder">{rgRneValue}</p>
    //   </div>
    //   <div className="container-label-value-holder">
    //     <h4 className="label-data-holder">Estado Civil</h4>
    //     <p className="value-data-holder">{maritalStatus}</p>
    //   </div>
    // </section>
  );
};

export default DataHolder;
