import React, { ReactNode, MouseEvent, useRef } from "react";
import iconClose from "../../assets/close_line.svg";
import "./ModalFull.css"; // Importe o arquivo CSS específico para estilos

interface ModalProps {
  onClose: () => void;
  style?: React.CSSProperties;
  classNameModalFull?: string;
  children: ReactNode; // Adicione esta linha para incluir a propriedade 'children'
}

const ModalFull: React.FC<ModalProps> = ({
  onClose,
  children,
  style,
  classNameModalFull = "modal-content-full",
}) => {
  const modalContentRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent<HTMLDivElement>) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(event.target as Node)
    ) {
      onClose();
    }
  };

  return (
    <div className="modal-container-full" onClick={handleClickOutside}>
      <div className={classNameModalFull} style={style} ref={modalContentRef}>
        {/* Adicione o ícone de fechamento */}
        <div className="close-icon" onClick={onClose}>
          <img src={iconClose} alt="icone letra x, em cor cinza" />
        </div>
        {/* Conteúdo da sua modal */}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default ModalFull;
