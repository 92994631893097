import React from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";

interface CountdownTimerProps {
  seconds: number;
  handleComplete?: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  seconds,
  handleComplete,
}) => {
  const renderer = ({ minutes, seconds, completed }: CountdownRenderProps) => {
    if (completed) {
      <span>Tempo esgotado!</span>;
      return;
    } else {
      return (
        <span>
          0{minutes}:{seconds < 10 && "0"}
          {seconds}
        </span>
      );
    }
  };

  return (
    <div
    // style={{ marginLeft: '10px',marginTop: '2px' }}
    >
      <Countdown
        date={Date.now() + seconds * 1000}
        renderer={renderer}
        onComplete={handleComplete}
      />
    </div>
  );
};

export default CountdownTimer;
