import { Select, SelectItem } from "@nextui-org/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import comentIcon from "../../assets/comentIcon.svg";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import ButtonComponent from "../../components/ButtonComponent";
import LeftHomeComponent from "../../components/leftHomeComponent/LeftHomeComponent";
import MainHomeComponent from "../../components/MainHomeComponent/MainHomeComponent";
import RightHomeComponent from "../../components/RightHomeComponent/RightHomeComponent";
import "./home.css"; // Certifique-se de importar o arquivo CSS
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  changeStatusLead,
  getDistribuidoras,
  updateDistribuidora,
} from "../../services/onboarding";
import {
  GET_DISTRIBUIDORAS,
  POST_GENERATE_TOKEN_FROM_SAFELINK,
  POST_UPDATE_DISTRIBUIDORA,
} from "../../query/constants";
// import { formatLastWord } from "../../utils/formatLastWord";
import { SessionContext } from "../../context/session";
import { getLocalStorage } from "../../utils/localStorage";
import LoadingFullPage from "../../components/Loading/LoadingFullPage/LoadingFullPage";
import { ScreenError } from "../../components/screenError";
import PdfAlert from "../../assets/installation/pdf-alert.png";
import { getImage } from "../../services/images";
import {
  JaInicieiCadastroModal,
  JaInicieiMeuCadastroModalRef,
} from "./JaInicieiCadastroModal";
import { generateSignedTokenFromSafelink } from "../../services/authentication";
import { useGetProtectedLead } from "../../hooks/useGetProtectedLead";
import { isParceiroEnvironment } from "../../services/environment";
import { textsPath } from "../../services/texts";

export const Home = () => {
  const navigate = useNavigate();
  const { setItemSession } = useContext(SessionContext);
  const [redirectEdit, setRedirectEdit] = useState<string>("");
  const [isLeadInValidacaoFatura, setIsLeadInValidacaoFatura] =
    useState<boolean>(false);
  const [isLeadReprovada, setIsLeadReprovada] = useState<boolean>(false);
  const [selectedDistributor, setSelectedDistributor] = useState("");
  const [searchParams] = useSearchParams();
  const modalRef = useRef<JaInicieiMeuCadastroModalRef | null>(null);
  const getProtectedLead = useGetProtectedLead();
  const [texts, setTexts] = useState({ description: "", title: "" });
  const { title, description } = texts;

  useEffect(() => {
    const localStoragePath = getLocalStorage("path");
    if (localStoragePath) {
      setRedirectEdit(localStoragePath);
    }

    const unsubscribeEmail = searchParams.get("unsubscribe-email");
    if (unsubscribeEmail != null) {
      putReproveLeadMutation.mutate(unsubscribeEmail);
      return;
    }

    const token = searchParams.get("token");
    if (token !== null) {
      authenticateSafelinkTokenMutation.mutate(token);
    }

    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);

  const authenticateSafelinkTokenMutation = useMutation({
    mutationFn: generateSignedTokenFromSafelink,
    mutationKey: [POST_GENERATE_TOKEN_FROM_SAFELINK],
    onSuccess: (data) => {
      const { token, email } = data;
      if (token) {
        sessionStorage.setItem("token", data.token);
        getProtectedLead.mutate();
      } else if (email) {
        modalRef.current?.open(email);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const putReproveLeadMutation = useMutation({
    mutationFn: changeStatusLead,
    mutationKey: ["put-reprove-lead"],
    onSuccess: (data) => {
      setIsLeadReprovada(true);
    },
    onError(erro) {
      console.log(erro);
    },
  });

  const updateDistribuidoraMutation = useMutation({
    mutationFn: updateDistribuidora,
    mutationKey: [POST_UPDATE_DISTRIBUIDORA],
    onSuccess: (data) => {
      if (data.status !== "SUCESSO") {
        throw new Error(data.status);
      }
      navigate("/installation");
    },
    onError: (error) => {
      const errorMessage = error.message;
      if (errorMessage === "INPUT_INVALIDO") {
        console.log(errorMessage);
      }
    },
  });

  const { data, isLoading, isError } = useQuery({
    queryFn: getDistribuidoras,
    queryKey: [GET_DISTRIBUIDORAS],
    staleTime: 1000 * 60 * 60 * 60,
  });

  type DistribuidoraOption = {
    value: string;
    label: string;
  };

  let optionsGetApi: DistribuidoraOption[] = [];

  if (!isLoading && !isError && data) {
    optionsGetApi = data.map(({ id, nome }) => ({
      value: id,
      label: nome,
    }));
  }

  const options = isParceiroEnvironment()
    ? optionsGetApi
    : [
        ...optionsGetApi,
        {
          value: "select-your-distributor",
          label: "Não encontrei minha distribuidora",
        },
      ];

  const handleSelectChange = (selectedOption: any) => {
    const distribuidora = selectedOption.target.value;
    const filteredDistribuidora = data?.find(
      (item) => item.id === distribuidora
    );
    if (filteredDistribuidora) {
      setItemSession(
        "dados-distribuidora",
        JSON.stringify(filteredDistribuidora)
      );
    }

    setItemSession("distribuidora", distribuidora);
    setSelectedDistributor(selectedOption.target.value); // Atualiza o estado com a opção selecionada
  };

  const handleButtonClick = () => {
    if (selectedDistributor === "select-your-distributor") {
      return navigate("/select-your-distributor");
    }
    if (selectedDistributor && !redirectEdit) {
      return navigate("/contact");
    } else if (redirectEdit) {
      const newDistribuidora = selectedDistributor;
      updateDistribuidoraMutation.mutate(newDistribuidora);
    }
  };

  const openModal = () => {
    modalRef.current?.open();
  };

  const loading =
    authenticateSafelinkTokenMutation.isPending ||
    getProtectedLead.isPending ||
    putReproveLeadMutation.isPending;

  return isLeadInValidacaoFatura ? (
    <ScreenError
      icon={PdfAlert}
      alt="Pdf com uma exclamação"
      texto="Você possui uma fatura em análise, em breve daremos um retorno."
      title="Validação de Fatura"
    />
  ) : isLeadReprovada ? (
    <ScreenError
      icon={PdfAlert}
      alt="Pdf com uma exclamação"
      texto="Seu cadastro na IVI foi cancelado. Caso mude de ideia, você pode contar com o nosso time de especialistas, que está sempre pronto para te auxiliar em todo o processo e construir uma relação de confiança e parceria!"
      title="Cadastro cancelado"
    />
  ) : (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={title}
        description={description}
        imgSrc={getImage("imgIvi")}
      />
      <RightHomeComponent
        cardHomeClass="container-home"
        questionSrc={question}
        commentIconSrc={comentIcon}
        title={title}
        description={description}
      >
        <div className="container-select-home">
          <Select
            onChange={handleSelectChange}
            label="Selecione sua distribuidora"
            className="max-w-xs select-inputs"
            style={{ width: "280px" }}
            classNames={{
              label: ["text-base text-black "],
            }}
          >
            {options.map((dest) => (
              <SelectItem key={dest.value} value={dest.value}>
                {dest.label}
              </SelectItem>
            ))}
          </Select>

          {!isParceiroEnvironment() && (
            <p className="p-cadastro-continue" onClick={() => openModal()}>
              {" "}
              Já iniciei meu cadastro
            </p>
          )}
          {selectedDistributor && (
            <ButtonComponent
              className="continue-button"
              // className="btn-homee"
              style={{
                marginTop: "16px",
              }}
              onClick={handleButtonClick}
              isDisabled={selectedDistributor === null}
            >
              Continuar
            </ButtonComponent>
          )}
        </div>
        {updateDistribuidoraMutation.isPending && <LoadingFullPage />}

        <JaInicieiCadastroModal
          ref={modalRef}
          setIsLeadInValidacaoFatura={setIsLeadInValidacaoFatura}
        />
      </RightHomeComponent>
      {loading && <LoadingFullPage />}
    </MainHomeComponent>
  );
};
