import logoIVI from "../../assets/logo.svg";
import styles from "./SeloIVI.module.css";

type SeloIVIProps = React.HTMLAttributes<HTMLSpanElement>;

const SeloIVI = ({ className, ...props }: SeloIVIProps) => {
  return (
    <span className={`${styles.seloContainer} ${className}`} {...props}>
      <img src={logoIVI} alt="Logo IVI Energia" className={styles.seloLogo} />
      <p className={styles.seloDescription}>
        A Energia Solar Compartilhada é oferecida em parceria com a IVI Energia.
        Você poderá receber comunicações diretamente em nome da IVI Energia.
      </p>
    </span>
  );
};

export default SeloIVI;
