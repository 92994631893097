import React, { useRef, useState } from "react";
import { ImBin2 } from "react-icons/im";
import ButtonComponent from "../ButtonComponent";
import "./card.css"; // Certifique-se de ter o arquivo CSS para o estilo do componente

interface CardProps {
  onFileUpload: (file: File) => void;
  onButtonClick: () => void;
  onRemoveFile: () => void;
  fileName: string | null;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({
  onFileUpload,
  onButtonClick,
  onRemoveFile,
  fileName,
  children,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    let validatedFile: any = [];

    event.dataTransfer.files[0].type.split("/").forEach((el) => {
      validatedFile.push(
        el.includes("pdf") || el.includes("jpg") || el.includes("png")
      );
    });
    if (!validatedFile.includes(true)) return;

    const file = event.dataTransfer.files[0];
    if (file) {
      // Enviar o arquivo para a função de callback para fazer o upload
      onFileUpload(file);
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      // Enviar o arquivo para a função de callback para fazer o upload
      onFileUpload(file);
    }
  };

  const handleRemoveFile = () => {
    // Remover o arquivo
    onRemoveFile();
  };

  const handleCardClick = () => {
    // Abrir a opção de upload ao clicar no componente Card
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      className={`centered-card${isDragging ? " dragging" : ""}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleCardClick}
    >
      {fileName ? (
        <div className="file-info">
          <span>{fileName}</span>
          <ImBin2 className="icon-bin" onClick={handleRemoveFile} />
        </div>
      ) : (
        <>
          {children}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileInputChange}
            accept="application/pdf"
          />
          <ButtonComponent className="btn-contact" onClick={onButtonClick}>
            Procure o Arquivo
          </ButtonComponent>
        </>
      )}
    </div>
  );
};

export default Card;
