import React, {
  MouseEvent,
  useRef,
  useState,
  useImperativeHandle,
} from "react";

import iconClose from "../../../assets/close_line.svg";
import styles from "./NewModal.module.css";

type NewModalProps = React.HTMLAttributes<HTMLDivElement>;

export type NewModalRef = {
  open: () => void;
  close: () => void;
};

const NewModalContext = React.createContext<any>(null);

const NewModal = React.forwardRef<NewModalRef, NewModalProps>(
  ({ children, ...props }, ref) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);

    const handleClickOutside = ({ target }: MouseEvent<HTMLDivElement>) => {
      const modal = modalRef.current;
      if (modal && !modal.contains(target as Node)) close();
    };

    useImperativeHandle(ref, () => ({
      open,
      close,
    }));

    return (
      <NewModalContext.Provider value={{ open, close }}>
        {isOpen && (
          <div
            className={styles.newModalBackground}
            onClick={handleClickOutside}
          >
            <div className={`${styles.newModalContainer}`} ref={modalRef}>
              <div className={styles.closeIcon} onClick={close}>
                <img src={iconClose} alt="icone letra x, em cor cinza" />
              </div>
              {children}
            </div>
          </div>
        )}
      </NewModalContext.Provider>
    );
  }
);

type NewModalContentProps = React.HTMLAttributes<HTMLDivElement>;

const NewModalContent = ({
  children,
  className = "",
}: NewModalContentProps) => {
  return (
    <div className={`${styles.newModalContent} ${className}`}>{children}</div>
  );
};

type NewModalTitleProps = React.HTMLAttributes<HTMLHeadingElement>;

const NewModalTitle = ({
  children,
  className = "",
  ...props
}: NewModalTitleProps) => {
  return (
    <h1 className={`${styles.newModalTitle} ${className}`} {...props}>
      {children}
    </h1>
  );
};

type NewModalHeaderProps = React.HTMLAttributes<HTMLDivElement>;

const NewModalHeader = ({
  children,
  className = "",
  ...props
}: NewModalHeaderProps) => {
  return (
    <header className={`${styles.newModalHeader} ${className}`} {...props}>
      {children}
    </header>
  );
};

type NewModalBodyProps = React.HTMLAttributes<HTMLParagraphElement>;

const NewModalBody = ({
  children,
  className = "",
  ...props
}: NewModalBodyProps) => {
  return (
    <div className={`${styles.newModalBody} ${className}`} {...props}>
      {children}
    </div>
  );
};

type NewModalFooterProps = React.HTMLAttributes<HTMLDivElement>;

const NewModalFooter = ({
  children,
  className = "",
  ...props
}: NewModalFooterProps) => {
  return (
    <footer className={`${styles.newModalFooter} ${className}`} {...props}>
      {children}
    </footer>
  );
};

type NewModalCloseButtonProps = React.HTMLAttributes<HTMLDivElement>;

const NewModalCloseButton = ({
  children,
  ...props
}: NewModalCloseButtonProps) => {
  const { close } = React.useContext(NewModalContext);

  return React.cloneElement(children as React.ReactElement, {
    onClick: close,
    ...props,
  });
};

export {
  NewModal,
  NewModalTitle,
  NewModalContent,
  NewModalFooter,
  NewModalBody,
  NewModalCloseButton,
  NewModalHeader,
};
