import React from "react";
import styles from "./ServicoIndisponivelModal.module.css";

import {
  NewModal,
  NewModalContent,
  NewModalFooter,
  NewModalBody,
  NewModalHeader,
  NewModalRef,
  NewModalTitle,
  NewModalCloseButton,
} from "../NewModal/NewModal";

import icon from "../../../assets/contact/cpfCnpj-alert.png";
import ButtonComponent from "../../ButtonComponent";

const ServicoIndisponivelModal = React.forwardRef<NewModalRef>((props, ref) => {
  return (
    <NewModal ref={ref}>
      <NewModalContent>
        <NewModalHeader>
          <img width="40px" height="40px" src={icon} alt="" />
          <NewModalTitle>Serviço indisponível no momento</NewModalTitle>
        </NewModalHeader>

        <NewModalBody>
          <p className={styles.textSize}>
            Estamos passando por uma instabilidade em nossos sistemas. Por
            favor, tente novamente mais tarde.
          </p>
        </NewModalBody>

        <NewModalFooter>
          <NewModalCloseButton>
            <ButtonComponent className={`continue-button ${styles.buttonSize}`}>
              Tentar novamente
            </ButtonComponent>
          </NewModalCloseButton>
        </NewModalFooter>
      </NewModalContent>
    </NewModal>
  );
});

export default ServicoIndisponivelModal;
