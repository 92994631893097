export const economiaAnual = (
  valorFornecimento: number,
  consumoMedia: number,
  tarifaDistribuidora: number,
  descontoPadrao: number
) => {
  let calculo =
    (consumoMedia - valorFornecimento) *
    12 *
    tarifaDistribuidora *
    descontoPadrao;

  let resultado = Math.abs(parseFloat(calculo.toFixed(2)));
  return resultado;
};

// ("consumoMedio"– “fornecimento” ) x 12 x "TarifaVigente" x "DescontoPadrao"
