export const POST_CRIAR_LEAD = "post-criar-lead";
export const POST_SEND_TOKEN = "post-send-token";
export const POST_DADOS_TITULAR_PF = "post-dados-titular-pf";
export const POST_DADOS_REPRESENTANTE = "post-dados-representante";
export const POST_DADOS_TITULAR_PJ = "post-dados-titular-pj";
export const POST_PARSE_FATURA = "post-parse-fatura";
export const GET_DISTRIBUIDORAS = "get-distribuidoras";
export const POST_CONFIRMAR_PARSE_FATURA = "post-confirmar-parse-fatura";
export const PUT_DADOS_TITULAR_PF = "put-dados-titular-pf";
export const PUT_DADOS_TITULAR_PJ = "put-dados-titular-pj";
export const UPDATE_DISTRIBUIDORA = "update-distribuidora";
export const POST_PREPARE_TERM = "post-prepare-term";
export const POST_UPDATE_DISTRIBUIDORA = "post-update-distribuidora";
export const GET_LEAD = "get-lead";
export const GET_LEAD_EXIST = "get-lead-exist";
export const POST_VALIDATE_CNPJ = "post-validate-cnpj";
export const PUT_LEAD_STATUS = "put-lead-status";
export const POST_CONFIRMAR_DADOS_LEAD = "post-confirm-dados-lead";
export const POST_VALIDATE_CPF = "post-validate-cpf";
export const GET_STATUS_LEAD = "get-status-lead";
export const POST_DIST_NOT_FOUND = "post-dist-not-found";
export const POST_MANUAL_FATURA = "post-manual-fatura";
export const GET_LEAD_PROTECTED = "get-lead-protected";
export const POST_GENERATE_TOKEN_FROM_SAFELINK = "post-generate-token-from-safelink";
