import React from "react";
import "./Card.css";

interface CardProps {
  imgSrc: string;
  altText: string;
  cardText: string;
  customClass?: string;
}

const Card: React.FC<CardProps> = ({
  imgSrc,
  altText,
  cardText,
  customClass,
}) => (
  <div className="card">
    <img src={imgSrc} alt={altText} className={customClass} />
    <div className={customClass}>
      <p className="text-card" dangerouslySetInnerHTML={{ __html: cardText }} />
    </div>
  </div>
);

export default Card;
