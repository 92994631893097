const keySecret = process.env.REACT_APP_SECRET_KEY;

export async function encryptData(data: string): Promise<string> {
  if (!keySecret) {
    throw new Error("Chave secreta não definida.");
  }
  if (keySecret.length !== 32 && keySecret.length !== 64) {
    throw new Error("Chave secreta inválida: deve ter 128 ou 256 bits.");
  }
  const keyBuffer = new TextEncoder().encode(keySecret);
  const keyCrypto = await window.crypto.subtle.importKey(
    "raw",
    keyBuffer,
    { name: "AES-CBC" },
    false,
    ["encrypt"]
  );
  const encodedData = new TextEncoder().encode(data);

  const iv = window.crypto.getRandomValues(new Uint8Array(16));

  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: "AES-CBC",
      iv: iv,
    },
    keyCrypto,
    encodedData
  );
  const buffer = new Uint8Array(iv.byteLength + encryptedData.byteLength);
  buffer.set(new Uint8Array(iv), 0);
  buffer.set(new Uint8Array(encryptedData), iv.byteLength);
  return Array.prototype.map
    .call(buffer, (x) => ("00" + x.toString(16)).slice(-2))
    .join("");
}

export async function decryptData(encryptedDataHex: string): Promise<string> {
  if (!keySecret) {
    throw new Error("Chave secreta não definida.");
  }
  if (keySecret.length !== 32 && keySecret.length !== 64) {
    throw new Error("Chave secreta inválida: deve ter 128 ou 256 bits.");
  }

  if (typeof encryptedDataHex !== "string") {
    throw new Error(
      "Dados criptografados inválidos: esperava-se uma string hexadecimal."
    );
  }

  const cleanedHex = encryptedDataHex.replace(/\s/g, "");
  const hexRegex = /^[0-9a-fA-F]+$/;
  if (!hexRegex.test(cleanedHex)) {
    throw new Error(
      "Dados criptografados inválidos: a string não é uma representação hexadecimal válida."
    );
  }
  const bytes = cleanedHex.match(/.{1,2}/g)?.map((byte) => parseInt(byte, 16));

  if (!bytes) {
    throw new Error("Erro ao converter a string hexadecimal em bytes.");
  }

  const encryptedDataBytes = new Uint8Array(bytes);

  const iv = encryptedDataBytes.slice(0, 16);

  const encryptedBytes = encryptedDataBytes.slice(16);

  const keyBuffer = new TextEncoder().encode(keySecret);
  const keyCrypto = await window.crypto.subtle.importKey(
    "raw",
    keyBuffer,
    { name: "AES-CBC" },
    false,
    ["decrypt"]
  );

  const decryptedData = await window.crypto.subtle.decrypt(
    {
      name: "AES-CBC",
      iv: iv,
    },
    keyCrypto,
    encryptedBytes
  );

  const decodedData = new TextDecoder().decode(decryptedData);

  return decodedData;
}
