import { Input, Select, SelectItem } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import comentIcon from "../../../assets/comentIcon.svg";
import logoIvi from "../../../assets/logo.svg";
import question from "../../../assets/question.svg";
import ButtonComponent from "../../../components/ButtonComponent";
import LeftHomeComponent from "../../../components/leftHomeComponent/LeftHomeComponent";
import MainHomeComponent from "../../../components/MainHomeComponent/MainHomeComponent";
import RightHomeComponent from "../../../components/RightHomeComponent/RightHomeComponent";
import "../../contact/contact.css";
import "./create.css";
import {
  getLocalStorage,
  removeItemStorage,
} from "../../../utils/localStorage";
import { useMutation } from "@tanstack/react-query";
import { createDadosTitularPf } from "../../../services/onboarding";
import {
  POST_DADOS_TITULAR_PF,
} from "../../../query/constants";
import { SessionContext } from "../../../context/session";
import LoadingFullPage from "../../../components/Loading/LoadingFullPage/LoadingFullPage";
import { getImage } from "../../../services/images";
import { textsPath } from "../../../services/texts";

export interface newIsInvalidProps {
  cpfInvalid?: boolean;
  nameInvalid: boolean;
  rgInvalid: boolean;
  rneInvalid: boolean;
  maritalStatusInvalid: boolean;
}

export interface newErrorMessagesProps {
  nameError: string;
  cpfError?: string;
  rgError: string;
  rneError: string;
  maritalStatusError: string;
}
export const CreateHolder = () => {
  const { getItemSession, setItemSession } = useContext(SessionContext);
  const [editDados, setEditDados] = useState<string>("");
  const navigate = useNavigate();
  const [nome, setNome] = useState<string>("");
  const [rg, setRg] = useState<string>("");
  const [rne, setRne] = useState<string>("");
  const [selectedNacionalidades, setSelectedNacionalidades] =
    useState("brasileiro");
  const [inputValue, setInputValue] = useState<string>("");
  const storageCpf = getItemSession("cpf");
  const [texts, setTexts] = useState({ description: "", title: "" })
  const { title, description } = texts;

  const [errorMgs, setErrorMgs] = useState({
    nameError: "",
    nacionalidadeError: "",
    cpfError: "",
    rgError: "",
    rneError: "",
    maritalStatusError: "",
  });

  const [isInvalid, setIsInvalid] = useState({
    nameInvalid: false,
    cpfInvalid: false,
    rgInvalid: false,
    rneInvalid: false,
    maritalStatusInvalid: false,
  });
  const [selectedMaritalStatus, setMaritalStatus] = useState<string>("");
  const formValidad = (): boolean => {
    if (nome && (rne || rg) && selectedMaritalStatus) {
      return true;
    }
    return false;
  };

  const nacionalidades = [
    { value: "brasileiro", label: "Brasileiro(a)" },
    { value: "estrangeiro", label: "Estrangeiro(a)" },
  ];

  const estadoCivil = [
    { value: "solteiro", label: "Solteiro(a)" },
    { value: "casado", label: "Casado(a)" },
    { value: "separado", label: "Separado(a)" },
    { value: "viuvo", label: "Viúvo(a)" },
  ];

  const mutation = useMutation({
    mutationFn: createDadosTitularPf,
    mutationKey: [POST_DADOS_TITULAR_PF],
    onSuccess() {
      navigate("/installation");
    },
    onError(error) {
      console.log(error);
    },
  });

  useEffect(() => {
    const storageValue = getItemSession("cpf");
    const localStoragePath = getLocalStorage("path");
    const storageHolderData: string | null = getItemSession("holderData");

    if (storageValue != null) {
      setInputValue(storageValue);
    }
    if (storageHolderData != null) {
      const holderDataObjeto = JSON.parse(storageHolderData);
      setNome(holderDataObjeto.nome);
      if (holderDataObjeto.rg) {
        setRg(holderDataObjeto.rg);
      } else if (holderDataObjeto.rne) {
        setRne(holderDataObjeto.rne);
      }
      setSelectedNacionalidades(holderDataObjeto.selectedNacionalidades);
      setMaritalStatus(holderDataObjeto.selectedMaritalStatus);
    }
    if (localStoragePath) {
      setEditDados(localStoragePath);
    }
    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);

  const handleSelectChange = (selectedOption: any) => {
    setSelectedNacionalidades(selectedOption.target.value); // Atualiza o estado com a opção selecionada
  };

  const handleMaritalStatusChange = (
    selectedOption: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newValue = selectedOption.target.value;
    setMaritalStatus(selectedOption.target.value);
    if (newValue !== "") {
      setIsInvalid({ ...isInvalid, maritalStatusInvalid: false });
      setErrorMgs({ ...errorMgs, maritalStatusError: "" });
      return;
    } else {
      setIsInvalid({ ...isInvalid, maritalStatusInvalid: true });
      setErrorMgs({
        ...errorMgs,
        maritalStatusError: "Selecione o estado civil do representante legal.",
      });
      return;
    }
  };

  const formatRNE = (value: string) => {
    let regex = /^[A-Za-z]\d{7}$/;
    if (regex.test(value)) {
      let valueLowCase = value.replace(/^([A-Za-z])(\d{6})(\d)$/, "$1$2-$3");
      let valueFormat = valueLowCase
        .charAt(0)
        .toUpperCase()
        .concat(valueLowCase.slice(1));
      return valueFormat;
    }
    return value;
  };

  const handleRgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let rgRneValue = event.target.value;

    if (selectedNacionalidades === "estrangeiro") {
      if (rgRneValue.length > 9) {
        rgRneValue = rgRneValue.slice(0, 9);
        rgRneValue[0].toUpperCase();
      }
      const formatedValueRne = formatRNE(rgRneValue);
      setIsInvalid({ ...isInvalid, rneInvalid: false });
      setErrorMgs({ ...errorMgs, rneError: "" });
      setRne(formatedValueRne);
      return;
    }
    setIsInvalid({ ...isInvalid, rgInvalid: false });
    setErrorMgs({ ...errorMgs, rgError: "" });
    setRg(rgRneValue);

    if (selectedNacionalidades === "estrangeiro") {
      if (rgRneValue.length !== 7) {
        setIsInvalid({ ...isInvalid, rneInvalid: true });
        return setErrorMgs({
          ...errorMgs,
          rgError: "RNE inválido",
        });
      }
    }

    if (selectedNacionalidades === "brasileiro") {
      if (rgRneValue.length > 15) {
        setIsInvalid({ ...isInvalid, rgInvalid: true });
        return setErrorMgs({
          ...errorMgs,
          rgError: "RG inválido",
        });
      }
    }
  };

  const handleNomeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    const nameParts = newName.split(" ");
    const regexNumber = /[0-9]/;

    if (regexNumber.test(newName)) return;

    if (newName.trim() === "") {
      setNome(newName);
      setErrorMgs({ ...errorMgs, nameError: "" });
      setIsInvalid({ ...isInvalid, nameInvalid: false });
      return;
    }

    if (nameParts.length < 2 || nameParts[1].length < 2) {
      setNome(newName);
      setErrorMgs({
        ...errorMgs,
        nameError: "Digite o nome e sobrenome",
      });
      setIsInvalid({ ...isInvalid, nameInvalid: true });
    } else {
      setNome(newName);
      setErrorMgs({ ...errorMgs, nameError: "" });
      setIsInvalid({ ...isInvalid, nameInvalid: false });
    }
  };

  const handleBackButtonClick = () => {
    removeItemStorage("cpf");
    if (editDados) {
      navigate(editDados);
    } else {
      navigate("/holder");
    }
  };

  const validateForm = () => {
    const newIsInvalid = {} as newIsInvalidProps;
    const newErrorMessages = {} as newErrorMessagesProps;

    if (nome === "") {
      newIsInvalid.nameInvalid = true;
      newErrorMessages.nameError = "Digite o nome e sobrenome";
    }

    if (selectedNacionalidades === "brasileiro") {
      if (!rg || rg.trim() === "") {
        newIsInvalid.rgInvalid = true;
        newErrorMessages.rgError = "Digite RG do representante legal";
        newErrorMessages.rneError = "";
      }
    }
    if (selectedNacionalidades === "estrangeiro") {
      if (!rne) {
        newIsInvalid.rneInvalid = true;
        newErrorMessages.rneError = "Digite RNE do representante legal ";
        newErrorMessages.rgError = "";
      }
    }

    if (selectedMaritalStatus === "") {
      newIsInvalid.maritalStatusInvalid = true;
      newErrorMessages.maritalStatusError =
        "Selecione o estado civil do representante legal.";
    }

    setIsInvalid({ ...isInvalid, ...newIsInvalid });
    setErrorMgs({ ...errorMgs, ...newErrorMessages });

    if (nome && (rne || rg) && selectedMaritalStatus) {
      return true;
    } else {
      return false;
    }
  };
  const createHolderLocalStorage = () => {
    const existholder = getItemSession("holderData");
    if (existholder !== null) {
      localStorage.removeItem(existholder);
    }
    const Typenacionalidades =
      selectedNacionalidades === "" ? "brasileiro" : selectedNacionalidades;
    const documentoAtual = Typenacionalidades === "brasileiro" ? "rg" : "rne";
    const RgOrRNE = documentoAtual === "rg" ? rg : rne;

    const holderData = {
      storageCpf,
      nome,
      [documentoAtual]: documentoAtual === "rg" ? rg : rne,
      selectedMaritalStatus,
      selectedNacionalidades: Typenacionalidades,
    };
    const holderDataString = JSON.stringify(holderData);
    setItemSession("holderData", holderDataString);
    if (editDados && holderData.storageCpf !== null) {
      navigate(editDados);
    } else {
      if (holderData.storageCpf !== null) {
        mutation.mutate({
          CPF: holderData.storageCpf,
          Nome: holderData.nome,
          RG: RgOrRNE,
          Nacionalidade: holderData.selectedNacionalidades,
          EstadoCivil: holderData.selectedMaritalStatus,
        });
      }
    }
  };

  const handleButtonClick = () => {
    if (!validateForm()) return;
    if (rne === "" && selectedNacionalidades === "estrangeiro") {
      setErrorMgs({ ...errorMgs, rgError: "" });
      setIsInvalid({ ...isInvalid, rneInvalid: true });
      setErrorMgs({
        ...errorMgs,
        rneError: "Digite RNE do representante legal",
      });
      return;
    }

    if (rg === "" && selectedNacionalidades === "brasileiro") {
      setErrorMgs({ ...errorMgs, rneError: "" });
      setIsInvalid({ ...isInvalid, rneInvalid: true });
      setErrorMgs({
        ...errorMgs,
        rneError: "Digite RG do representante legal",
      });
      return;
    }
    createHolderLocalStorage();
  };

  return (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={title}
        description={description}
        imgSrc={getImage("contaDeLuz")}
      />
      <RightHomeComponent
        questionSrc={question}
        commentIconSrc={comentIcon}
        cardHomeClass="container-create-holder-cpf"
        title={title}
        description={description}
      >
        <div className="container-form-button-cpf">
          <div className="container-form-cpf">
            <div className="container-cpf-create">
              <Input
                style={{ color: "black" }}
                type="text"
                size="md"
                label="CPF"
                value={inputValue}
                disabled
                classNames={{
                  label: ["text-black "],
                  input: ["text-noto"],
                }}
              />
            </div>
            {/* nome */}
            <div className="container-inputs-next-ui ">
              <Input
                type="text"
                size="md"
                label="Nome"
                value={nome}
                isInvalid={isInvalid.nameInvalid}
                className="max-w-xs"
                onChange={handleNomeChange}
                classNames={{
                  label: ["text-black"],
                  input: ["text-noto"],
                  inputWrapper: [
                    "bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent ",
                  ],
                }}
              />
            </div>
            {errorMgs.nameError && (
              <p className="error-mgs-create-cpf">{errorMgs.nameError}</p>
            )}
            <div>
              {/* nacionalidade */}
              <Select
                onChange={(e) => handleSelectChange(e)}
                label="Nacionalidade"
                selectedKeys={[selectedNacionalidades || "brasileiro"]}
                defaultSelectedKeys={["brasileiro"]}
                className="max-w-xs select-inputs"
                classNames={{
                  label: ["text-black "],
                }}
              >
                {nacionalidades.map((dest) => (
                  <SelectItem key={dest.value} value={dest.value}>
                    {dest.label}
                  </SelectItem>
                ))}
              </Select>
            </div>
            {errorMgs.nacionalidadeError && (
              <p className="error-mgs-create-cpf">
                {errorMgs.nacionalidadeError}
              </p>
            )}
            {/* RG */}
            <div className="container-inputs-next-ui">
              <Input
                type="text"
                size="md"
                isInvalid={
                  selectedNacionalidades === "estrangeiro"
                    ? isInvalid.rneInvalid
                    : isInvalid.rgInvalid
                }
                label={selectedNacionalidades === "estrangeiro" ? "RNE" : "RG"}
                value={selectedNacionalidades === "estrangeiro" ? rne : rg}
                onChange={handleRgChange}
                classNames={{
                  label: ["text-black"],
                  input: ["text-noto"],
                  inputWrapper: [
                    "bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent ",
                  ],
                }}
              />
            </div>
            {errorMgs.rgError && (
              <p className="error-mgs-create-cpf">{errorMgs.rgError}</p>
            )}
            {errorMgs.rneError && (
              <p className="error-mgs-create-cpf">{errorMgs.rneError}</p>
            )}
            {/* estado civil */}
            <div className="container-inputs-next-ui">
              <Select
                onChange={handleMaritalStatusChange}
                selectedKeys={
                  selectedMaritalStatus ? [selectedMaritalStatus] : undefined
                }
                label="Estado Civil"
                labelPlacement="inside"
                isInvalid={isInvalid.maritalStatusInvalid}
                className="max-w-xs select-inputs"
                classNames={{
                  label: ["text-black "],
                  trigger: [
                    "bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent ",
                  ],
                }}
              >
                {estadoCivil.map((dest) => (
                  <SelectItem key={dest.value} value={dest.value}>
                    {dest.label}
                  </SelectItem>
                ))}
              </Select>
            </div>
            {errorMgs.maritalStatusError && (
              <p className="error-mgs-create-cpf">
                {errorMgs.maritalStatusError}
              </p>
            )}
          </div>
          <div className="button-container-holder">
            <ButtonComponent
              className="back-button"
              // className="btn-voltar"
              onClick={handleBackButtonClick}
            >
              Voltar
            </ButtonComponent>

            <ButtonComponent
              className="continue-button"
              onClick={handleButtonClick}
              isDisabled={formValidad() ? false : true}
            >
              Continuar
            </ButtonComponent>
          </div>
        </div>
        {mutation.isPending && <LoadingFullPage />}
      </RightHomeComponent>
    </MainHomeComponent>
  );
};
