import React from "react";
import styles from "./TitularExistenteModal.module.css";

import {
  NewModal,
  NewModalContent,
  NewModalFooter,
  NewModalBody,
  NewModalHeader,
  NewModalRef,
  NewModalTitle,
  NewModalCloseButton,
} from "../NewModal/NewModal";

import icon from "../../../assets/contact/alert-email.png";
import ButtonComponent from "../../ButtonComponent";
import { useNavigate } from "react-router-dom";

type TitularExistenteModalProps = {
  documentType: "CPF" | "CNPJ" | "";
};

const TitularExistenteModal = React.forwardRef<
  NewModalRef,
  TitularExistenteModalProps
>(({ documentType }, ref) => {
  const navigate = useNavigate();

  const handleClick = () => navigate("/contact");

  return (
    <NewModal ref={ref}>
      <NewModalContent>
        <NewModalHeader>
          <img width="40px" height="40px" src={icon} alt="" />
          <NewModalTitle>
            Esse {documentType} já está vinculado a um e-mail
          </NewModalTitle>
        </NewModalHeader>

        <NewModalBody>
          <p className={styles.textSize}>
            O {documentType} informado já possui um cadastro na plataforma com
            um e-mail diferente.
          </p>
        </NewModalBody>

        <NewModalFooter>
          <ButtonComponent
            className={`back-button ${styles.buttonSize}`}
            onClick={handleClick}
          >
            Alterar e-mail
          </ButtonComponent>

          <NewModalCloseButton>
            <ButtonComponent className={`continue-button ${styles.buttonSize}`}>
              Alterar {documentType}
            </ButtonComponent>
          </NewModalCloseButton>
        </NewModalFooter>
        
      </NewModalContent>
    </NewModal>
  );
});

export default TitularExistenteModal;
