import { useState } from "react";
import styles from './calculator.module.css';

const Calculator = () => {
  const [value, setValue] = useState(8000);

  // Função para formatar o valor
  const formatValue = (value: number) => {
    return Number(value).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(event.target.value));
  };

  // Calcula e formata o valor atual e o valor calculado
  const valueFormatted = formatValue(value);
  const calculatedValue =  (value - 200) * 0.12 * 12;
  const calculatedValueFormatted = formatValue(calculatedValue);

  return (
    <section className={styles['calculator-content']} id="calculator">
      <div className={styles['calculator-content']}>
        <div className={styles['calculator']}>
          <div className={styles['title-content']}>
            <h1 className={styles.headingTitle}>Gasto mensal da conta de luz</h1>
          </div>
          <div className={styles.sliderContainer}>
            <div className={styles.volumeLabel}>
              {valueFormatted}
            </div>
            <input
              type="range"
              min="400"
              max="20000"
              value={value}
              onChange={handleSliderChange}
              className={styles.slider}
              style={{ '--slider-value': value } as React.CSSProperties}
            />
          </div>
          <div className={styles.economyBox}>
            <span className={styles.economyText}>A economia anual será de:</span>
            <span className={styles.economyValue}>R$ {calculatedValueFormatted}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calculator;
