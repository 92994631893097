import React from "react";
import Loading from "../Loading";
import "./LoadingFullPage.css";

const LoadingFullPage = () => {
  return (
    <section className="container-loading">
      <Loading />
    </section>
  );
};

export default LoadingFullPage;
