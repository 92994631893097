import { Input } from "@nextui-org/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { PDFDocument } from "pdf-lib";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import comentIcon from "../../assets/comentIcon.svg";
import { EyeFilledIcon } from "../../assets/eyesIcon/EyeFilledIcon";
import { EyeSlashFilledIcon } from "../../assets/eyesIcon/EyeSlashFilledIcon";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import ButtonComponent from "../../components/ButtonComponent";
import Card from "../../components/card/Card";
import LeftHomeComponent from "../../components/leftHomeComponent/LeftHomeComponent";
import LoadingFullPage from "../../components/Loading/LoadingFullPage/LoadingFullPage";
import MainHomeComponent from "../../components/MainHomeComponent/MainHomeComponent";
import { InstallationRegistered } from "../../components/modal/InstallationRegistered/InstallationRegistered";
import Modal from "../../components/modal/Modal";
import { NewModalRef } from "../../components/modal/NewModal/NewModal";
import RightHomeComponent from "../../components/RightHomeComponent/RightHomeComponent";
import { SessionContext } from "../../context/session";
import { useGetDistribuidora } from "../../hooks/useGetDistribuidora";
import {
  POST_CONFIRMAR_PARSE_FATURA,
  POST_MANUAL_FATURA,
  POST_PARSE_FATURA,
} from "../../query/constants";
import { getImage } from "../../services/images";
import { validateManual } from "../../services/lead";
import { confirmarDadosFatura, parseFatura } from "../../services/onboarding";
import { textsPath } from "../../services/texts";
import ErrorReadPDFModal from "./ErrorReadPDFModal/ErrorReadPDFModal";
import "./installation.css";

export const Installation = () => {
  const navigate = useNavigate();
  const { setItemSession, getItemSession } = useContext(SessionContext);
  const [open, setOpen] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  const [errorMgs, setErrorMgs] = useState("");
  const [emptyFile, setEmptyFile] = useState<boolean>(true);
  const [cnpjFlux, setCnpjFlux] = useState<boolean>(false);
  const [passwordPdf, setPasswordPdf] = useState<string>("");
  const [fileFull, setFileFull] = useState<File>();
  const [erroPassword, setErroPassword] = useState<boolean>(false);
  const [texts, setTexts] = useState({ description: "", title: "" });
  const { title, description } = texts;
  const cnpj = getItemSession("cnpj");

  useEffect(() => {
    setupTexts();
  }, []);

  const errorReadPDFModalRef = useRef<NewModalRef>(null);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);

  const manualvalidate = useMutation({
    mutationFn: validateManual,
    mutationKey: [POST_MANUAL_FATURA],
    onSuccess: (data) => {
      navigate("/pdf-error-validation");
    },
    onError: (erro) => {
      console.log(erro);
    },
  });
  const distribuidora = useGetDistribuidora();

  const mutationConfirmationParseFatura = useMutation({
    mutationFn: confirmarDadosFatura,
    mutationKey: [POST_CONFIRMAR_PARSE_FATURA],
    onSuccess(data) {
      if (data.status === "INPUT_INVALIDO") {
        throw new Error(data.erros);
      } else if (data.status === "FALHA") {
        throw new Error(data.erro);
      }
      navigate("/confirm-your-data");
    },
    onError(erro) {
      console.log(erro.message);
    },
  });

  const mutationParseFatura = useMutation({
    mutationFn: parseFatura,
    mutationKey: [POST_PARSE_FATURA],
    onSuccess(data, formData) {
      // console.log(data);

      if (data.status !== "SUCESSO") {
        throw new Error(data.erro);
      }
      if (
        data.status === "SUCESSO" &&
        data.statusCadastro === "INSTALACAO_EXISTENTE"
      ) {
        return setOpen(true);
      }
      if (
        data.status === "SUCESSO" &&
        data.statusCadastro === "BAIXO_CONSUMO"
      ) {
        return navigate("/unapproved-account");
      }

      setDadosInstalacao(data.fatura);
      mutationConfirmationParseFatura.mutate(formData);
    },
    onError(erro: AxiosError) {
      const erroStatus = erro.response?.status;
      const erroData = erro.response?.data as { status: string };
      console.log(erroStatus);
      if (erroStatus === 422) {
        // serErrorReadPdf(true);
        errorReadPDFModalRef.current?.open();
        return;
      }
      if (erroStatus === 400 && erroData?.status === "wrong_password") {
        setErrorMgs("Senha incorreta");
        setErroPassword(true);
        return;
      }
    },
  });

  function setDadosInstalacao(fatura: any) {
    setItemSession("dados-fatura", JSON.stringify(fatura));
  }

  useEffect(() => {
    if (cnpj != null) {
      setCnpjFlux(true);
    }
  }, [cnpj]);

  const transformData = (file: File) => {
    const formData = new FormData();

    formData.append("File", file);
    return formData;
  };

  const handleFileUpload = (file: File) => {
    setEmptyFile(false);
    setErrorMgs("");
    setUploadedFileName(file.name);
    setFileFull(file);
  };

  const isPdfProtect = async (file: File): Promise<boolean> => {
    const uint8Array = await file.arrayBuffer();
    try {
      const fileDocument = await PDFDocument.load(uint8Array, {
        ignoreEncryption: true,
      });
      if (fileDocument.isEncrypted) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleButtonClick = async () => {
    if (!uploadedFileName && emptyFile) {
      return setErrorMgs("Faça o upload da sua conta de energia.");
    }
    if (!fileFull) return;
    if ((await isPdfProtect(fileFull)) && !passwordPdf) {
      setErroPassword(true);
      setErrorMgs("Informe a senha do seu arquivo");
      return;
    }
    const formData = transformData(fileFull);
    if (!formData) return;
    if (passwordPdf && fileFull) {
      formData.append("Password", passwordPdf);
    }
    distribuidora.mutate();
    mutationParseFatura.mutate(formData);
  };

  function handlePasswordPdf(event: ChangeEvent<HTMLInputElement>) {
    setPasswordPdf(event.target.value);
    if (passwordPdf.length === 3) {
      setErroPassword(false);
      setErrorMgs("");
    }
  }

  const handleRemoveFile = () => {
    // Lógica para remover o arquivo
    setEmptyFile(true);
    setUploadedFileName(null);
  };

  const handleBackButtonClick = () => {
    if (cnpjFlux) {
      navigate("/create-holder-cnpj");
      return;
    }
    navigate("/create-holder");
  };

  const handleButtonClickOtherInstallation = () => {
    handleRemoveFile();
    setOpen(false);
  };

  const handleButtonClickUpload = () => {
    // Lógica para lidar com o clique no botão Continuar
  };

  const handleValidationManualFatura = () => {
    if (!fileFull) return;
    const formData = transformData(fileFull);
    if (!formData) return;
    if (passwordPdf && fileFull) {
      formData.append("Password", passwordPdf);
    }
    manualvalidate.mutate(formData);
  };

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={title}
        description={description}
        imgSrc={getImage("installation")}
      />
      <RightHomeComponent
        questionSrc={question}
        commentIconSrc={comentIcon}
        cardHomeClass="container-installation"
        title={title}
        description={description}
      >
        <Card
          onFileUpload={handleFileUpload}
          onButtonClick={handleButtonClickUpload}
          onRemoveFile={handleRemoveFile}
          fileName={uploadedFileName}
        >
          {/* Conteúdo a ser exibido no Card, se necessário */}
          <p className="text-inner-card">Arraste a conta de energia para cá ou</p>
        </Card>
        {emptyFile && <p className="error-empty-file">{errorMgs}</p>}

        {!uploadedFileName && (
          <h3 className="asterisco" style={{ color: "#727272" }}>
            *PDF ou imagem (PNG ou JPG)
          </h3>
        )}
        {erroPassword && <p className="error-error-file">{errorMgs}</p>}
        <div className="container-input-password">
          <Input
            isInvalid={erroPassword}
            value={passwordPdf}
            onChange={handlePasswordPdf}
            label="Senha do arquivo (opcional)"
            color="primary"
            size="md"
            endContent={
              <button
                className="focus:outline-none py-2 "
                type="button"
                onClick={toggleVisibility}
              >
                {isVisible ? (
                  <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none " />
                ) : (
                  <EyeFilledIcon className="text-2xl text-default-400  pointer-events-none  " />
                )}
              </button>
            }
            type={isVisible ? "text" : "password"}
            classNames={{
              label: ["text-normal"],
              inputWrapper: [
                "bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent ",
              ],
              input: ["text-base"],
            }}
          />
        </div>

        <div className="button-container-installation" style={{ margin: " 0" }}>
          <ButtonComponent
            className="back-button"
            onClick={handleBackButtonClick}
          >
            Voltar
          </ButtonComponent>

          <ButtonComponent
            className="continue-button"
            onClick={handleButtonClick}
            isDisabled={!emptyFile ? false : true}
          >
            Continuar
          </ButtonComponent>
        </div>
        {open && (
          <div className="modal-position">
            <Modal
              onClose={() => setOpen(false)}
              style={{
                width: "398px",
                height: "292px",
                display: "flex",
                flexDirection: "column",
              }}
              className="container-children-installation"
            >
              <InstallationRegistered />
              <div className="button-modal-container-email">
                <ButtonComponent
                  className="back-button"
                  onClick={() => setOpen(false)}
                >
                  Fechar
                </ButtonComponent>
                <ButtonComponent
                  className="continue-button"
                  onClick={handleButtonClickOtherInstallation}
                  isDisabled={false}
                  style={{
                    width: "192px",
                  }}
                >
                  Cadastrar outra conta
                </ButtonComponent>
              </div>
            </Modal>
          </div>
        )}

        <ErrorReadPDFModal
          ref={errorReadPDFModalRef}
          handleValidationManualFatura={handleValidationManualFatura}
        />

        {(mutationParseFatura.isPending ||
          mutationConfirmationParseFatura.isPending ||
          manualvalidate.isPending) && <LoadingFullPage />}
        {/* {manualvalidate.isPending && <LoadingFullPage />} */}
      </RightHomeComponent>
    </MainHomeComponent>
  );
};
