import React, { ReactNode } from "react";
import "./MainHomeComponent.css"; // Importe o arquivo CSS específico

interface MainHomeComponent {
  children?: ReactNode;
}

const MainHomeComponent: React.FC<MainHomeComponent> = ({ children }) => {
  return <div className="main-home">{children}</div>;
};

export default MainHomeComponent;
