import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <svg viewBox="25 25 50 50" className="circle-loading">
      <circle r="20" cy="50" cx="50" className="circle-inside-svg"></circle>
    </svg>
  );
};

export default Loading;
