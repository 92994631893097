import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import comentIcon from "../../../assets/comentIcon.svg";
import logoIvi from "../../../assets/logo.svg";
import question from "../../../assets/question.svg";
import ButtonComponent from "../../../components/ButtonComponent";
import LeftHomeComponent from "../../../components/leftHomeComponent/LeftHomeComponent";
import MainHomeComponent from "../../../components/MainHomeComponent/MainHomeComponent";
import RightHomeComponent from "../../../components/RightHomeComponent/RightHomeComponent";
import "../../contact/contact.css";
import "./createCnpj.css";
import { removeItemStorage } from "../../../utils/localStorage";
import { useMutation } from "@tanstack/react-query";
import {
  DadosTitularPjProps,
  createDadosTitularPJ,
} from "../../../services/onboarding";
import { POST_DADOS_REPRESENTANTE } from "../../../query/constants";
import { SessionContext } from "../../../context/session";
import LoadingFullPage from "../../../components/Loading/LoadingFullPage/LoadingFullPage";
import { getImage } from "../../../services/images";
import { FormCreateCnpj } from "./FormCreateCnpj";
import ErrorModal, {
  ErrorModalRef,
} from "../../../components/modal/ErrorModal/ErrorModal";
import { AxiosError } from "axios";
import { textsPath } from "../../../services/texts";

export const CreateHolderCNPJ = () => {
  const { getItemSession, setItemSession } = useContext(SessionContext);
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({
    nome: "",
    cpf: "",
    rg: "",
    rne: "",
    nacionalidade: "",
    maritalStatus: "",
  });
  const modalRef = useRef<ErrorModalRef | null>(null);

  const [inputValue, setInputValue] = useState<DadosTitularPjProps>({
    CNPJ: "",
    RazaoSocial: "",
  });
  const [editDados, setEditDados] = useState<string>("");
  const [texts, setTexts] = useState({ description: "", title: "" });
  const { title, description } = texts;

  const { nome, cpf, rg, rne, nacionalidade, maritalStatus } = formData;

  useEffect(() => {
    const storageValue = getItemSession("cnpj");
    if (storageValue != null) {
      const cnpjStoarge = JSON.parse(storageValue);
      setInputValue(cnpjStoarge);
    }
    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);

  const createTitularPjMutation = useMutation({
    mutationFn: createDadosTitularPJ,
    mutationKey: [POST_DADOS_REPRESENTANTE],
    onSuccess: () => {
      navigate("/installation");
    },
    onError: (erro: AxiosError) => {
      console.log(erro);
      const message = erro.response?.data
        ? undefined
        : "Falha ao processar dados.";
      const errorItems = erro.response?.data
        ? Object.values(erro.response?.data)
        : undefined;
      modalRef.current?.showError({
        title: "Erro na criação de cadastro",
        message,
        errorItems,
      });
    },
  });

  const handleBackButtonClick = () => {
    removeItemStorage("cnpj");
    if (editDados) {
      navigate(editDados);
    } else {
      navigate("/holder");
    }
  };

  const createHolderCNPJLocalStorage = () => {
    const storageValue = getItemSession("cnpj");
    const existHolderCNPJ = getItemSession("holderCNPJ");
    if (existHolderCNPJ) {
      localStorage.removeItem(existHolderCNPJ);
    }
    const typenacionalidades =
      nacionalidade === "" ? "brasileiro" : nacionalidade;
    const documentoAtual = typenacionalidades === "brasileiro" ? "rg" : "rne";
    const documentoOficial = documentoAtual === "rg" ? rg : rne;

    const holderCnpjData = {
      storageValue,
      nome,
      [documentoAtual]: documentoAtual === "rg" ? rg : rne,
      cpf,
      selectedMaritalStatus: maritalStatus,
      selectedNacionalidades: typenacionalidades,
    };

    const holderCnpjDataString = JSON.stringify(holderCnpjData);
    setItemSession("holderCNPJ", holderCnpjDataString);
    if (editDados && holderCnpjData.storageCpf !== null) {
      navigate(editDados);
    } else {
      if (!storageValue) return;
      const cnpj = JSON.parse(storageValue) as DadosTitularPjProps;
      createTitularPjMutation.mutate({
        CNPJ: cnpj.CNPJ,
        RazaoSocial: cnpj.RazaoSocial,
        Representante: {
          Nome: holderCnpjData.nome,
          CPF: holderCnpjData.cpf,
          RG: documentoOficial,
          Nacionalidade: holderCnpjData.selectedNacionalidades,
          EstadoCivil: holderCnpjData.selectedMaritalStatus,
        },
      });
    }
  };

  const handleButtonClick = () => {
    if (!isFormValid) return;
    createHolderCNPJLocalStorage();
  };

  return (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={title}
        description={description}
        imgSrc={getImage("contaDeLuz")}
      />
      <RightHomeComponent
        questionSrc={question}
        commentIconSrc={comentIcon}
        cardHomeClass="container-all-form-cnpj"
        title={title}
        description={description}
      >
        <FormCreateCnpj
          inputValue={inputValue}
          setEditDados={setEditDados}
          setFormData={setFormData}
          setIsFormValid={setIsFormValid}
        />
        <div className="button-container-create-cnpj">
          <ButtonComponent
            className="back-button"
            // className="btn-voltar"
            onClick={handleBackButtonClick}
          >
            Voltar
          </ButtonComponent>
          <ButtonComponent
            className="continue-button"
            // className="btn-contact"
            onClick={handleButtonClick}
            isDisabled={!isFormValid}
          >
            Continuar
          </ButtonComponent>
        </div>
        {createTitularPjMutation.isPending && <LoadingFullPage />}

        <ErrorModal ref={modalRef} />
      </RightHomeComponent>
    </MainHomeComponent>
  );
};
