import { Input, Select, SelectItem } from "@nextui-org/react";
import { useContext, useEffect, useState } from "react";
import { SessionContext } from "../../../context/session";
import { getLocalStorage } from "../../../utils/localStorage";
import { isValidName } from "../../../services/validations";

type FormData = {
  nome: string;
  cpf: string;
  rg: string;
  rne: string;
  nacionalidade: string;
  maritalStatus: string;
};

export const FormCreateCnpj = ({
  inputValue,
  setEditDados,
  setFormData,
  setIsFormValid,
}: {
  inputValue: {
    RazaoSocial: string;
    CNPJ: string;
  };
  setEditDados: (editDados: string) => void;
  setFormData: (formData: FormData) => void;
  setIsFormValid: (isFormValid: boolean) => void;
}) => {
  const { getItemSession } = useContext(SessionContext);
  const [nome, _setNome] = useState<string>("");
  const setNome = (nome: string) => {
    updateFormData({ nome });
    _setNome(nome);
  };
  const [cpf, _setCpf] = useState<string>("");
  const setCpf = (cpf: string) => {
    updateFormData({ cpf });
    _setCpf(cpf);
  };
  const [rg, _setRg] = useState<string>("");
  const setRg = (rg: string) => {
    updateFormData({ rg });
    _setRg(rg);
  };
  const [rne, _setRne] = useState<string>("");
  const setRne = (rne: string) => {
    updateFormData({ rne });
    _setRne(rne);
  };
  const [selectedMaritalStatus, _setMaritalStatus] = useState<string>("");
  const setMaritalStatus = (maritalStatus: string) => {
    updateFormData({ maritalStatus });
    _setMaritalStatus(maritalStatus);
  };
  const [errorMgs, setErrorMgs] = useState({
    nameError: "",
    nacionalidadeError: "",
    cpfError: "",
    rgError: "",
    rneError: "",
    maritalStatusError: "",
  });

  const updateFormData = (updates: any) => {
    const formData = {
      nome,
      cpf,
      rg,
      rne,
      nacionalidade: selectedNacionalidades,
      maritalStatus: selectedMaritalStatus,
      ...updates,
    };

    setFormData(formData);
    setIsFormValid(validateForm(formData));
  };

  const options = [
    { value: "solteiro", label: "Solteiro(a)" },
    { value: "casado", label: "Casado(a)" },
    { value: "separado", label: "Separado(a)" },
    { value: "viuvo", label: "Viúvo(a)" },
  ];
  const [isInvalid, _setIsInvalid] = useState({
    nameInvalid: false,
    cpfInvalid: false,
    rgInvalid: false,
    rneInvalid: false,
    maritalStatusInvalid: false,
  });
  const setIsInvalid = (isInvalid: {
    nameInvalid: boolean;
    cpfInvalid: boolean;
    rgInvalid: boolean;
    rneInvalid: boolean;
    maritalStatusInvalid: boolean;
  }) => {
    _setIsInvalid(isInvalid);
  };

  const nacionalidades = [
    { value: "brasileiro", label: "Brasileiro(a)" },
    { value: "estrangeiro", label: "Estrangeiro(a)" },
  ];
  const [selectedNacionalidades, _setSelectedNacionalidades] = useState(
    nacionalidades[0].value
  );

  const setSelectedNacionalidades = (nacionalidade: string) => {
    updateFormData({ nacionalidade });
    _setSelectedNacionalidades(nacionalidade);
  };

  useEffect(() => {
    const localStoragePath = getLocalStorage("path");
    const storageHolderCnpjData: string | null = getItemSession("holderCNPJ");
    if (storageHolderCnpjData) {
      const updates: any = {};
      const holderCnpjString = JSON.parse(storageHolderCnpjData);
      updates.nome = holderCnpjString.nome;
      _setNome(holderCnpjString.nome);
      if (holderCnpjString.rg) {
        _setRg(holderCnpjString.rg);
        updates.rg = holderCnpjString.rg;
      } else if (holderCnpjString.rne) {
        _setRne(holderCnpjString.rne);
        updates.rne = holderCnpjString.rne;
      }
      _setCpf(holderCnpjString.cpf);
      updates.cpf = holderCnpjString.cpf;
      _setSelectedNacionalidades(holderCnpjString.selectedNacionalidades);
      updates.nacionalidade = holderCnpjString.selectedNacionalidades;
      _setMaritalStatus(holderCnpjString.selectedMaritalStatus);
      updates.maritalStatus = holderCnpjString.selectedMaritalStatus;

      updateFormData(updates);
    }
    if (localStoragePath) {
      setEditDados(localStoragePath);
    }
  }, []);

  const handleMaritalStatusChange = (
    selectedOption: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setMaritalStatus(selectedOption.target.value);
    setIsInvalid({
      ...isInvalid,
      maritalStatusInvalid: selectedOption.target.value == "",
    });
    setErrorMgs({
      ...errorMgs,
      maritalStatusError:
        selectedOption.target.value == ""
          ? "Selecione o estado civil do representante legal."
          : "",
    });
  };
  const handleSelectChange = (selectedOption: any) => {
    setSelectedNacionalidades(selectedOption.target.value);
  };

  const formatRNE = (value: string) => {
    let regex = /^[A-Za-z]\d{7}$/;
    if (regex.test(value)) {
      let valueLowCase = value.replace(/^([A-Za-z])(\d{6})(\d)$/, "$1$2-$3");
      let valueFormat = valueLowCase
        .charAt(0)
        .toUpperCase()
        .concat(valueLowCase.slice(1));
      return valueFormat;
    }
    return value;
  };

  const handleRgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let rgRneValue = event.target.value;

    if (selectedNacionalidades === "estrangeiro") {
      if (rgRneValue.length > 9) {
        rgRneValue = rgRneValue.slice(0, 9);
        rgRneValue[0].toUpperCase();
      }
      const formatedValueRne = formatRNE(rgRneValue);
      setIsInvalid({ ...isInvalid, rneInvalid: false });
      setErrorMgs({ ...errorMgs, rneError: "" });
      setRne(formatedValueRne);
      return;
    }
    setIsInvalid({ ...isInvalid, rgInvalid: false });
    setErrorMgs({ ...errorMgs, rgError: "" });
    setRg(rgRneValue);

    if (selectedNacionalidades === "estrangeiro") {
      if (rgRneValue.length !== 7) {
        setIsInvalid({ ...isInvalid, rneInvalid: true });
        return setErrorMgs({
          ...errorMgs,
          rgError: "RNE inválido",
        });
      }
    }

    if (selectedNacionalidades === "brasileiro") {
      if (rgRneValue.length > 15) {
        setIsInvalid({ ...isInvalid, rgInvalid: true });
        return setErrorMgs({
          ...errorMgs,
          rgError: "RG inválido",
        });
      }
    }
  };

  const handleNomeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    const regexNumber = /[0-9]/;

    if (regexNumber.test(newName)) return;

    setNome(newName);
    setIsInvalid({ ...isInvalid, nameInvalid: !isValidName(newName) });
    setErrorMgs({
      ...errorMgs,
      nameError: isValidName(newName) ? "" : "Digite o nome e sobrenome",
    });
  };

  const handleInputFocus = (index: number) => {
    let errorMessage = document.querySelectorAll(".error-input")[
      index
    ] as HTMLElement;

    if (errorMessage) {
      errorMessage.style.display = "none";
      errorMessage.innerText = "";
    }
  };
  const formatCpf = (value: string) => {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    if (value.length > 11) {
      value = value.slice(0, 11);
    }
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return value;
  };

  const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let cpfIsValue = e.target.value.replace(/[^\d]/g, "");

    if (cpfIsValue.length > 11) {
      cpfIsValue = cpfIsValue.slice(0, 11);
    }

    const formattedValue = formatCpf(cpfIsValue);

    if (cpfIsValue.length < 11) {
      setIsInvalid({ ...isInvalid, cpfInvalid: true });
      setCpf(formattedValue);
      setErrorMgs({
        ...errorMgs,
        cpfError: "CPF inválido. Verifique os dados digitados.",
      });
      return;
    } else {
      setIsInvalid({ ...isInvalid, cpfInvalid: false });
      setErrorMgs({
        ...errorMgs,
        cpfError: "",
      });
    }

    setCpf(formattedValue);
  };
  const validateForm = (formData: FormData) => {
    return (
      isValidName(formData.nome) &&
      validateNacionalidade(formData) &&
      !!formData.maritalStatus &&
      formData.cpf.length === 14
    );
  };

  const validateNacionalidade = ({ nacionalidade, rg, rne }: FormData) => {
    return (
      (nacionalidade === "brasileiro" && !!rg.trim()) ||
      (nacionalidade === "estrangeiro" && !!rne.trim())
    );
  };

  return (
    <>
      <div className="container-createHolderCNPJ">
        <div className="inputs-cnpj-razao-social">
          <div className="input-cnpj-block">
            <Input
              type="text"
              size="md"
              label="CNPJ"
              value={inputValue.CNPJ}
              classNames={{
                input: ["text-noto"],
              }}
            />
          </div>
          <div className="input-cnpj-block">
            <Input
              type="text"
              size="md"
              label="Razão Social"
              value={inputValue.RazaoSocial}
              className="bg-[#727272]"
              classNames={{
                input: ["text-noto"],
              }}
            />
          </div>
        </div>

        <div className="container-form-Createcnpj">
          <div
            style={{
              width: "280px",
            }}
          >
            <div>
              <strong className="subtitle-step-representante">
                Representante Legal
              </strong>
            </div>
          </div>
          {/* nome */}
          <div className="container-input-next-ui-cnpj">
            <Input
              type="text"
              size="md"
              label="Nome"
              isInvalid={isInvalid.nameInvalid}
              value={nome}
              className="max-w-xs select-inputs"
              onChange={handleNomeChange}
              onFocus={() => handleInputFocus(0)}
              classNames={{
                label: ["text-black"],
                input: ["text-noto"],
                inputWrapper: [
                  "bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent ",
                ],
              }}
            />
          </div>
          {errorMgs.nameError && (
            <p className="error-mgs-create-cnpj">{errorMgs.nameError}</p>
          )}

          {/* Nacionalidade */}
          <div className="container-input-next-ui-cnpj">
            <Select
              onChange={(e) => handleSelectChange(e)}
              label="Nacionalidade"
              className="max-w-xs select-inputs"
              selectedKeys={[selectedNacionalidades]}
              defaultSelectedKeys={["brasileiro"]}
              onFocus={() => handleInputFocus(1)}
              classNames={{
                label: ["text-black"],
              }}
            >
              {nacionalidades.map((dest) => (
                <SelectItem key={dest.value} value={dest.value}>
                  {dest.label}
                </SelectItem>
              ))}
            </Select>
          </div>

          {errorMgs.nacionalidadeError && (
            <p className="error-mgs-create-cnpj">
              {errorMgs.nacionalidadeError}
            </p>
          )}
          {/* cpf */}
          <div className="container-input-next-ui-cnpj">
            <Input
              type="text"
              size="md"
              isInvalid={isInvalid.cpfInvalid}
              label="CPF"
              value={cpf}
              onChange={(e) => handleCpfChange(e)}
              classNames={{
                label: ["text-black"],
                input: ["text-noto"],
                inputWrapper: [
                  "bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent ",
                ],
              }}
            />
          </div>
          {errorMgs.cpfError && (
            <p className="error-mgs-create-cnpj">{errorMgs.cpfError}</p>
          )}

          {/* RG */}
          <div className="container-input-next-ui-cnpj">
            <Input
              type="text"
              size="md"
              isInvalid={
                selectedNacionalidades === "estrangeiro"
                  ? isInvalid.rneInvalid
                  : isInvalid.rgInvalid
              }
              label={selectedNacionalidades === "estrangeiro" ? "RNE" : "RG"}
              value={selectedNacionalidades === "estrangeiro" ? rne : rg}
              onChange={handleRgChange}
              onFocus={() => handleInputFocus(2)}
              classNames={{
                label: ["text-black "],
                input: ["text-noto"],
                inputWrapper: [
                  "bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent ",
                ],
              }}
            />
          </div>
          {errorMgs.rgError && (
            <p className="error-mgs-create-cnpj">{errorMgs.rgError}</p>
          )}
          {errorMgs.rneError && (
            <p className="error-mgs-create-cnpj">{errorMgs.rneError}</p>
          )}

          {/* Estado Civil */}
          <div className="container-input-next-ui-cnpj">
            <Select
              onChange={handleMaritalStatusChange}
              label="Estado Civil"
              labelPlacement="inside"
              selectedKeys={
                selectedMaritalStatus ? [selectedMaritalStatus] : undefined
              }
              isInvalid={isInvalid.maritalStatusInvalid}
              size="md"
              className="max-w-xs select-inputs"
              classNames={{
                label: ["text-black "],
                trigger: [
                  "bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent ",
                ],
              }}
              onFocus={() => handleInputFocus(3)}
            >
              {options.map((dest) => (
                <SelectItem key={dest.value} value={dest.value}>
                  {dest.label}
                </SelectItem>
              ))}
            </Select>
          </div>
          {errorMgs.maritalStatusError && (
            <p className="error-mgs-create-cnpj">
              {errorMgs.maritalStatusError}
            </p>
          )}
        </div>
      </div>
    </>
  );
};
