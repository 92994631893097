import React from "react";
import "./InstallationRegistered.css";
import houseAlertIcon from "../../../assets/installation/modal-installation-registered/house-alert.svg";

export const InstallationRegistered = () => {
  return (
    <div className="content-modal-installation-registered">
      <div className="content-installation">
        <img src={houseAlertIcon} alt="íconde de uma casa com alerta no meio" />
        <h1 className="title-modal-installation-registered">
          Instalação já cadastrada
        </h1>
        <p className="subtitle-modal-installation-registered">
          Os dados já foram inseridos no nosso sistema.
        </p>
      </div>
    </div>
  );
};
