/* eslint-disable no-restricted-globals */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import comentIcon from "../../assets/comentIcon.svg";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import LeftHomeComponent from "../../components/leftHomeComponent/LeftHomeComponent";
import MainHomeComponent from "../../components/MainHomeComponent/MainHomeComponent";
import RightHomeComponent from "../../components/RightHomeComponent/RightHomeComponent";
import CardSimple from "../../components/card/CardSimple";
import "./contractOfAdhesion.css";
import { SessionContext } from "../../context/session";
import { useMutation } from "@tanstack/react-query";
import { updateLeadStatus } from "../../services/onboarding";
import { PUT_LEAD_STATUS } from "../../query/constants";
import LoadingFullPage from "../../components/Loading/LoadingFullPage/LoadingFullPage";
import { getImage } from "../../services/images";
import { textsPath } from "../../services/texts";

export const ContractOfAdhesion = () => {
  const { getItemSession } = useContext(SessionContext);
  const navigate = useNavigate();
  const [texts, setTexts] = useState({ description: "", title: "" })
  const { title, description } = texts;
  
  useEffect(() => {
    const sessionTerm = getItemSession("term");

    if (sessionTerm) {
      const widget = new (window as any).Clicksign(sessionTerm);
      widget.endpoint = process.env.REACT_APP_CLICKSIGN_ENDPOINT;
      widget.origin = location.origin;
      widget.mount("container");
      widget.on("signed", function () {
        sessionStorage.removeItem("term");
        updateLeadStatusmutation.mutate();
      });
    }
    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);

  const updateLeadStatusmutation = useMutation({
    mutationFn: updateLeadStatus,
    mutationKey: [PUT_LEAD_STATUS],
    onSuccess: () => {
      navigate("/registration-completed");
    },
  });

  return (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={title}
        description={description}
        imgSrc={getImage("contractOfAdhesion")}
      />
      <RightHomeComponent
        questionSrc={question}
        commentIconSrc={comentIcon}
        cardHomeClass="card-home-two"
      >
        <CardSimple id="container" />
        {updateLeadStatusmutation.isPending && <LoadingFullPage />}
      </RightHomeComponent>
    </MainHomeComponent>
  );
};
